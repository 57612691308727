import React, { FunctionComponent } from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import TipYourHat from "../../components/tipyourhat";

const News: FunctionComponent = () => {
  return (
    <Layout menu="single">
      <TipYourHat />
    </Layout>
  );
};

export default News;

export const Head = () => {
  return (
    <SEO title="RED HAT SOCIAL RESPONSIBILITY">
      <script type="application/ld+json">{JSON.stringify({})}</script>
    </SEO>
  );
};
