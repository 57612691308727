import { Link } from "gatsby";
import React, { FunctionComponent, useEffect, useState } from "react";
import styled from "styled-components";
import { AuthLink } from "../links";
const ArticleBar = styled.div`
  position: relative;
  width: 100%;
  background-color: #fff;
  color: #000;
  z-index: 0;
  margin-bottom: 0px;
  padding-top: 50px;
  padding-bottom: 80px;
  height: 97%;
  .titlehead,
  .rhsocial {
    font-family: "Inter", sans-serif;
    font-weight: 900;
    font-size: 48px;
    letter-spacing: 0.6px;
    text-transform: uppercase;
    line-height: 1.1;
    color: #000;
    text-decoration: none;
  }
  .rhsocial h2 {
    color: #000;
  }
  .rhsocial:hover,
  .rhsocial h2:hover {
    color: #ee0000;
    text-decoration: underline;
    .titlehead {
      color: #ee0000;
    }
  }
  p {
    margin-top: 5px;
    font-size: 18px;
  }
  .firstMe {
    margin-top: 40px;
  }
  .standingsGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-bottom: 30px;
    .first {
      background-image: url("https://cdn.fedscoop.com/upgather/redhatgov/tipyourhat/First_Place.png");
      background-repeat: no-repeat;
      background-size: contain;
      text-align: center;
      background-position: center center;
      img {
        max-width: 50%;
        margin-top: 33%;
      }
    }
    .second {
      background-image: url("https://cdn.fedscoop.com/upgather/redhatgov/tipyourhat/Second_Place.png");
      background-repeat: no-repeat;
      background-size: contain;
      text-align: center;
      background-position: center center;
      img {
        max-width: 48%;
        margin-top: 35%;
      }
    }
    .third {
      background-image: url("https://cdn.fedscoop.com/upgather/redhatgov/tipyourhat/Third_Place.png");
      background-repeat: no-repeat;
      background-size: contain;
      text-align: center;
      background-position: center center;
      img {
        width: 49%;
        min-height: 50%;
        margin-top: 34%;
      }
    }
  }

  h2.keystats {
    font-family: "Inter", sans-serif;
    font-weight: 900;
    font-size: 24px;
  }
  .statslist {
    font-size: 18px;
    line-height: 30px;
  }
  .charityCell {
    display: grid;
    grid-template-columns: 200px 1fr;
    grid-column-gap: 40px;
    border-bottom: 1px dotted #000;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .firstCell {
    border-top: 1px dotted #000;
    margin-top: 40px;
  }
  .lastOne {
    border-bottom: 0px;
  }
  .tipIcon img {
    width: 100%;
  }
  .tipIcon img:hover {
    border: 1px solid #000;
  }
  .tipDesc h2 {
    font-family: "Inter", sans-serif;
    font-weight: 900;
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 24px;
  }
  .leftText {
    text-align: left;
  }

  .clearMe {
    clear: both;
  }

  .redMe {
    color: #ee0000 !important;
    font-family: "Inter", sans-serif;
    font-weight: 400;
  }

  .wrapUp {
    line-height: 24px;
    max-width: 1200px;
    margin: 0 auto;
  }

  @media only screen and (min-width: 1441px) and (max-width: 1600px) {
  }
  @media only screen and (min-width: 1200px) and (max-width: 1441px) {
    .wrapUp {
      max-width: 90%;
    }
  }
  @media only screen and (max-width: 1199px) and (min-width: 768px) {
    .wrapUp {
      max-width: 90%;
    }

    .titleBlock {
      height: 100px;
    }
  }
  @media only screen and (min-width: 374px) and (max-width: 767px) {
    .wrapUp {
      max-width: 90%;
    }
    .charityCell,
    .standingsGrid {
      grid-template-columns: 1fr;
    }
    .tipIcon img {
      margin-bottom: 20px;
    }
    .titlehead {
      font-size: 38px;
    }
  }
  @media only screen and (min-width: 360px) and (max-width: 373px) {
    .wrapUp {
      max-width: 90%;
    }
    .charityCell {
      grid-template-columns: 1fr;
    }
    .titleBlock {
      height: 100%;
    }
    .tipIcon img {
      margin-bottom: 20px;
    }
    .titlehead {
      font-size: 32px;
    }
  }
  @media only screen and (min-width: 281px) and (max-width: 359px) {
    .wrapUp {
      max-width: 90%;
    }
    .charityCell {
      grid-template-columns: 1fr;
    }
    .titlehead {
      font-size: 28px;
    }
    .tipIcon img {
      margin-bottom: 20px;
    }
    .titleBlock {
      height: 100%;
    }
  }
  @media only screen and (min-width: 120px) and (max-width: 280px) {
    .wrapUp {
      max-width: 90%;
    }
    .charityCell {
      grid-template-columns: 1fr;
    }
    .titlehead {
      line-height: 1;
      font-size: 24px;
    }
    .tipIcon img {
      margin-bottom: 20px;
    }
    .titleBlock {
      height: 100%;
    }
  }
`;

const Articles = (props) => {
  const [scoring, updateScoring] = useState<string[]>([
    "WWProject",
    "NIH",
    "DirectRelief",
  ]);
  const [count, setCount] = useState(0);

  const shuffledArray = (arr: string[]) => arr.sort(() => 0.5 - Math.random());

  useEffect(() => {
    const timeout = setInterval(() => {
      updateScoring([...shuffledArray(scoring)]);
    }, 30000);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <ArticleBar>
      <div className="wrapUp">
        <a
          href="https://www.redhat.com/en/about/community-social-responsibility"
          target="_blank"
          className="rhsocial"
        >
          <h1 className="titlehead">RED HAT SOCIAL RESPONSIBILITY</h1>
        </a>
        <h2 className="keystats">CURRENT TIP YOUR HAT STANDINGS</h2>
        <div className="standingsGrid">
          <div className="first">
            <img
              src={`https://cdn.fedscoop.com/upgather/redhatgov/tipyourhat/${scoring[0]}.jpg`}
            />
          </div>
          <div className="second">
            <img
              src={`https://cdn.fedscoop.com/upgather/redhatgov/tipyourhat/${scoring[1]}.jpg`}
            />
          </div>
          <div className="third">
            <img
              src={`https://cdn.fedscoop.com/upgather/redhatgov/tipyourhat/${scoring[2]}.jpg`}
            />
          </div>
        </div>
        <p>
          Red Hat will be donating $12,000 in total to three charities - Wounded
          Warrior Project, The Children’s Inn, and COVID-19 Direct Relief Fund.
          Government Symposium attendees will be asked to answer questions
          throughout the event to earn points for their charity. At the end of
          the event, we will announce first place, receiving $7,000, second
          place, receiving $3,500, and third place, receiving $1,500.
        </p>
        <p>
          Looking back at the past year, most of us will remember uncertainty
          and change brought on by events like the pandemic and the fight for
          racial equity. Through our collaborative open culture, Red Hatters
          worked together to support nonprofit organizations that were working
          to solve these issues, as well as support the local communities where
          we live and work.
        </p>
        <h2 className="keystats">Key Stats</h2>
        <ul className="statslist">
          <li>
            We donated more than <span className="redMe">$4.5 million</span> to
            charitable and educational causes, more than double the previous
            reported year.
          </li>
          <li>
            We matched more than <span className="redMe">$875,000</span> in Red
            Hatter donations to 2,000+ charities in 2020.
          </li>
          <li>
            Through our Team-19 and Social Innovation programs, Red Hatters
            donated <span className="redMe">5,000+</span> hours to external open
            source projects dedicated to overcoming the challenges of COVID-19.
          </li>
        </ul>
        <div className="charityGrid">
          <div className="charityCell firstCell">
            <div className="tipIcon">
              <a href="https://www.woundedwarriorproject.org/" target="_blank">
                <img
                  src="https://cdn.fedscoop.com/upgather/redhatgov/WoundedWarrior.png"
                  alt="Wounded Warrior Project"
                />
              </a>
            </div>
            <div className="tipDesc">
              <a
                href="https://www.woundedwarriorproject.org/"
                target="_blank"
                className="rhsocial"
              >
                <h2>WOUNDED WARRIOR</h2>
              </a>
              <p>
                Every warrior has a next mission. We know that the transition to
                civilian life is a journey. And for every warrior, family
                member, and caregiver, that journey looks different. Wounded
                Warrior Project is there for the first step, and each step that
                follows. Because they believe that every warrior should have a
                positive future to look forward to. There’s always another goal
                to achieve, another mission to discover. Wounded Warrior Project
                is their partner in that mission. Through a range of programs
                and services, they are transforming the way America’s injured
                veterans are empowered, employed, and engaged in our
                communities.
              </p>
              <p className="firstMe">
                <span className="redMe">52,000+</span> servicemen and women
                physically injured in recent military conflicts
              </p>
              <p>
                <span className="redMe">500,000</span> living with invisible
                wounds, from depression to post-traumatic stress disorder
              </p>
              <p>
                <span className="redMe">320,000</span> experiencing debilitating
                brain trauma
              </p>
            </div>
          </div>

          <div className="charityCell">
            <div className="tipIcon">
              <a href="https://childrensinn.org/" target="_blank">
                <img
                  src="https://cdn.fedscoop.com/upgather/redhatgov/NIH.png"
                  alt="The Children's Inn at NIH"
                />
              </a>
            </div>
            <div className="tipDesc">
              <a
                href="https://childrensinn.org/"
                target="_blank"
                className="rhsocial"
              >
                <h2>NIH - THE CHILDREN'S INN</h2>
              </a>
              <p>
                The Children’s Inn at NIH is a residential “place like home’’
                for families with children, teens and young adults participating
                in leading-edge research studies at the National Institutes of
                Health (NIH) in Bethesda, Maryland. The NIH Clinical Center is
                the nation’s premier biomedical research hospital, providing an
                opportunity for our families to be partners in advancing medical
                discoveries and improving the health of people all over the
                world. While the NIH takes care of the child’s medical needs,
                The Inn tends to the child’s heart, soul and spirit.
              </p>
              <p className="firstMe">
                Served <span className="redMe">407</span> families from across
                the United States and the world. This is due to the COVID-19
                Pandemic. In a typical year, we serve more than 1,500 families.
              </p>
              <p>Provided free lodging to families for 8,750 nights.</p>
              <p>
                Offered <span className="redMe">429</span> occurrences of
                virtual recreational, educational and therapeutic activities for
                children, teens, young adults and their caregivers
              </p>
              <p>
                Enabled children, teens and young adults to participate in 537
                clinical trials, advancing the treatment of cancer, bone and
                growth disorders, mental illness, genetic conditions and other
                serious health issues
              </p>
            </div>
          </div>

          <div className="charityCell lastOne">
            <div className="tipIcon">
              <a
                href="https://www.directrelief.org/emergency/coronavirus-outbreak/?gclid=EAIaIQobChMIt4u0w7Lu8gIVA5SGCh3wWAD4EAAYASAAEgJkw_D_BwE"
                target="_blank"
              >
                <img
                  src="https://cdn.fedscoop.com/upgather/redhatgov/DirectRelief.png"
                  alt="COVID-19 Direct Relief"
                />
              </a>
            </div>
            <div className="tipDesc">
              <a
                href="https://www.directrelief.org/emergency/coronavirus-outbreak/?gclid=EAIaIQobChMIt4u0w7Lu8gIVA5SGCh3wWAD4EAAYASAAEgJkw_D_BwE"
                target="_blank"
                className="rhsocial"
              >
                <h2>COVID-19 Direct Relief</h2>
              </a>
              <p>
                Every aspect of Direct Relief’s organizational capacity is
                devoted to the coronavirus response. The organization is
                leveraging its global distribution infrastructure and financial
                resources to aid in the response, with emergency deliveries of
                critical medical resources — vaccines, PPE, and medications —
                leaving daily for medical facilities across the U.S. and world.
                Emergency funding from Direct Relief is bolstering health
                facilities in hard-hit areas.
              </p>
              <p className="firstMe">
                <span className="redMe">$2.3 billion</span> in medical aid
              </p>
              <p>
                <span className="redMe">$87.8 million</span> in medical aid
              </p>
              <p>
                <span className="redMe">41,100</span> emergency aid shipments
                delivered
              </p>
              <p>
                <span className="redMe">2+ million</span> vaccines
              </p>
              <p>
                <span className="redMe">5,500 tons</span> of PPE and other
                medical resources provided
              </p>
              <p>
                <span className="redMe">83 million</span> masks
              </p>
              <p>
                <span className="redMe">3 million</span> face shields
              </p>
              <p>
                <span className="redMe">33 million</span> gloves
              </p>
              <p>
                <span className="redMe">2.2 million</span> gowns and coveralls
              </p>
              <p>
                <span className="redMe">56</span> US states and territories and
                106 countries supported
              </p>
            </div>
          </div>
        </div>
      </div>
    </ArticleBar>
  );
};

export default Articles;
